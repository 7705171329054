<div
  class="Modal flex flex-col items-center justify-start gap-8 rounded-xl bg-white p-6 shadow"
>
  <div
    class="Content flex flex-col items-center justify-start gap-5 self-stretch"
  >
    <div
      [ngClass]="{
        'border-[#ECFDF3] bg-[#D1FADF]': type === 'success',
        'border-[#FEF3F2] bg-[#FEE4E2]': type === 'error',
        'border-[#FFFAEB] bg-[#FEF0C7]': type === 'warning'
      }"
      class="FeaturedIcon flex h-12 w-12 items-center justify-center rounded-[1.75rem] border-[0.5rem] p-3"
    >
      <div class="CheckCircle flex h-6 w-6 flex-col items-start justify-start">
        @switch (type) {
          @case ("success") {
            <fa-icon
              class="text-[#039855]"
              [icon]="icon ?? faCircleCheck"
              size="lg"
            />
          }
          @case ("error") {
            <fa-icon
              class="text-[#D92D20]"
              [icon]="icon ?? faCircleExclamation"
              size="lg"
            />
          }
          @case ("warning") {
            <fa-icon
              class="text-[#DC6803]"
              [icon]="icon ?? faTriangleExclamation"
              size="lg"
            />
          }
          @default {
            <fa-icon
              class="text-[#039855]"
              [icon]="icon ?? faCircleCheck"
              size="lg"
            />
          }
        }
      </div>
    </div>
    <div class="flex flex-col items-start justify-start gap-2 self-stretch">
      <div class="self-stretch text-center text-lg font-semibold text-gray-900">
        {{ modalTitle }}
      </div>
      <div class="self-stretch text-center text-sm font-normal text-gray-500">
        @if (modalMessage?.length) {
          {{ modalMessage }}
        }
      </div>
    </div>
  </div>
  <div class="ModalActions flex w-full items-start justify-start gap-3">
    @if (secondaryBtnStr?.length) {
      <ion-button
        class="flex-1"
        [ngClass]="{ 'max-w-[50%]': primaryBtnStr?.length }"
        hesBtn
        hesColor="secondary"
        (click)="cancel()"
        >{{ secondaryBtnStr }}</ion-button
      >
    }
    @if (primaryBtnStr?.length) {
      <ion-button
        [ngClass]="{
          'hes-btn--error': type === 'error',
          'max-w-[50%]': secondaryBtnStr?.length
        }"
        class="flex-1"
        hesBtn
        (click)="confirm()"
        >{{ primaryBtnStr }}</ion-button
      >
    }
  </div>
</div>
